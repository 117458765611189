import { computed } from 'vue'
import { template } from '@shein/common-function'


export default ({ language = {}, cartInfo = {}, underPriceAbtValue, flashSaleGroupList, integrityPromotionInfo } = {}) => {
  const promotionDiscountInfo = computed(() => {
    return cartInfo.promotion_discount_info || []
  })


  const promotionList = computed(() => {
    const promotionMap = {}
    const showDetailTipTids = [1, 9, 14, 15, 16, 17, 18, 19, 25, 26]
    const showTitleTipTids = [2, 3, 4, 8, 10, 11, 12, 13, 22, 24, 29, 30, 31, 32]
    const titleLangMap = {
      '2': language.SHEIN_KEY_PWA_14961,
      '3': language.SHEIN_KEY_PWA_17527,
      '4': language.SHEIN_KEY_PWA_14961,
      '4-0': language.SHEIN_KEY_PWA_14961,
      '4-8': language.SHEIN_KEY_PWA_31167,
      '8': language.SHEIN_KEY_PWA_17528,
      '10-1': language.SHEIN_KEY_PWA_14949, // 常规闪购
      '10-2': language.SHEIN_KEY_PWA_21931, // 新人闪购
      '10-3': language.SHEIN_KEY_PWA_21933, // 特殊闪购
      '10-4': language.SHEIN_KEY_PWA_26340, // 分人群专区闪购
      '10-5': language.SHEIN_KEY_PWA_26340, // 不分人群专区闪购
      '10-6': language.SHEIN_KEY_PWA_29800, // 品牌特卖
      '11': language.SHEIN_KEY_PWA_25021,
      '12': language.SHEIN_KEY_PWA_17529,
      '13': language.SHEIN_KEY_PWA_15828,
      '22': language.SHEIN_KEY_PWA_15828,
      '24': language.SHEIN_KEY_PWA_17531,
      '29': language.SHEIN_KEY_PWA_21802,
      '30': underPriceAbtValue ? language.SHEIN_KEY_PWA_23491 : language.SHEIN_KEY_PWA_23492,
      '32': language.SHEIN_KEY_PWA_26427,
    }
    
    promotionDiscountInfo.value?.forEach(item => {
      const id = item.id
      const tid = +item.typeId
  
      // Price Drop 情况
      if (tid == 31 ) {
        if(item.promotion_logo_type == 3) {
          promotionMap[id] = {
            tip: language.SHEIN_KEY_PWA_26878,
            typeId: tid,
            id: id,
          }
        } else if(item.promotion_logo_type != 1 && item.promotion_logo_type != 3) {
          promotionMap[id] = {
            tip: language.SHEIN_KEY_PWA_27511,
            typeId: tid,
            id: id,
          }
        } else {
          promotionMap[id] = {
            tip: language.SHEIN_KEY_PWA_27511,
            typeId: tid,
            id: id,
          }
        }
      }

      const isShowDetailTip = showDetailTipTids.includes(tid)
      const isShowTitleTip = showTitleTipTids.includes(tid)
  
      if (!promotionMap[id]) promotionMap[id] = { typeId: tid, id: id }
  
      if (isShowDetailTip) {
        let promotionInfoContent = integrityPromotionInfo?.[id]?.content || {}
        if (promotionInfoContent.tips) {
          const tipsIndex = promotionInfoContent.tipsIndex
          const tipsList = promotionInfoContent.tips.list
          promotionMap[id].tip = tipsList[tipsIndex] || ''
          promotionMap[id].name = tipsList[tipsIndex] || ''
        }
      } else if (isShowTitleTip) {
        // 闪购活动
        if (tid === 10) {
          const { flash_type } = integrityPromotionInfo?.[id] || {}
          if ([4, 5].includes(flash_type) && flashSaleGroupList.value?.[id]?.[0].promotionTag?.typeIdText) {
            // 要求与商品行展示的活动名称一致
            promotionMap[id].tip = flashSaleGroupList.value?.[id]?.[0].promotionTag?.typeIdText // 获取闪购活动第一个商品的 typeIdText
          } else {
            promotionMap[id].tip = titleLangMap[`${tid}-${flash_type}`]
          }
        } else if(tid === 4) { // 全场满赠（全场满赠、付费会员权益礼物）
          const { promotion_logo_type } = integrityPromotionInfo[id]
          // typeId=4都是增品，所以用free gift做文案兜底
          promotionMap[id].tip = titleLangMap[`${tid}-${promotion_logo_type}`] || titleLangMap[`${tid}-0`]
        } else if (tid != 31) {
          promotionMap[id].tip = titleLangMap[tid]
        }
  
        promotionMap[id].name = promotionMap[id].tip
        let activityName = `<em>${promotionMap[id].tip}</em>`
        if(tid == 29) {
          activityName = `<em class="prime">${promotionMap[id].tip}</em>`
          promotionMap[id].icon = 'prime'
        } else if(tid == 10 && integrityPromotionInfo?.[id]?.flash_type == 1) {
          activityName = `<em class="flash">${promotionMap[id].tip}</em>`
          promotionMap[id].icon = 'flash'
        }
        promotionMap[id].tip = template(activityName, language.SHEIN_KEY_PWA_29170)
      } else {
        const discount = cartInfo?.total_discount_price?.amountWithSymbol
        promotionMap[id].tip = template(`<em>${discount}</em>`, language.SHEIN_KEY_PWA_29171)
      }
    })
  
    return Object.values(promotionMap)
  })

  return {
    promotionList
  }
}
