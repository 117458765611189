import { TRANSPORT_TYPE_SHOP } from 'public/src/js/constants'
import { template } from '@shein/common-function'

const getters = {
  isCouponAutoUse (state) {
    return !state.is_manual_usedCoupon && ((state.canAutoUsePrime || state.isPrimeMember) || (state.usedXtraCode || state.isCancelXtra))
  },
  first_coupon_code (state) {
    let { autoUse, couponCode = '' } = state.checkout.results?.firstCoupon || {}
    let first_coupon_code = !!(+autoUse) && couponCode
    return first_coupon_code ? couponCode : ''
  },
  isFreePrice (state) {
    return state.price?.actual_shipping_price?.amountWithSymbol && +state.price?.actual_shipping_price?.amount == 0
  },
  // 支付图标
  paymentLogoList (state) {
    return state.paymentLogoInfo?.info?.logoList || []
  },
  storeAddressSite(state) { // 有店配的站点
    return state.locals?.SHOP_TRANSIT?.site
  },
  iconPrefix(state){
    return state.isSSR && !state.prerenderSsr ? 'suiiconfont-critical-checkout' : 'suiiconfont'
  },
  fsIconPrefix(state){
    return state.isSSR && !state.prerenderSsr ? 'suiiconfont-critical' : 'suiiconfont'
  },
  isClient(){
    return typeof window !== 'undefined'
  },
  localLang(state){
    return state.locals?.lang
  },
  isSiteMallList (state) { 
    return state.checkout?.results?.mallInfo?.mallInfoForSite?.length > 1
  },
  communalNotice(state){
    return state.checkout.communalNotice || ''
  },
  serviceType(state) {
    return state.locals?.SERVER_TYPE || gbCommonInfo?.SERVER_TYPE
  },
  // 是否展示辅助决策信息 => 已推全
  isShowSupportInfo(state){
    return state.checkoutAbtInfo.SupportInfo?.param?.SupportInfoShow === 'Show'
  },
  twJumpUrl(state){
    return state.checkoutAbtInfo.TaiwanJumpUrl?.param == 'TaiwanDownloadOn' ? true : false
  },
  // 是否展示 推荐券
  recommendCouponShowAbt(state){
    return state.checkoutAbtInfo.OrderPageCouponTest?.param?.RecommendCouponShow == 'Hide' ? true : false
  },
  // 是否展示付费会员可点击标识
  sheinclubStackableAbt(state){
    return state.checkoutAbtInfo.sheinclubStackable?.param == 'notsupportSheinclubStackable' ? false : true
  },
  // 开启总优惠金额展示
  showSaveAmount(state){
    return state.checkoutAbtInfo.Shownewsaveamount?.param?.show_new_save_amount == 'on' ? true : false
  },
  // 是否开启税费abt
  isShowTotalFee(state){
    return state.checkoutAbtInfo.TotalFee?.param == 'ShowTotalFee' ? true : false
  },
  // 是否展示时效百分比
  timePercentAbt(state){
    return state.checkoutAbtInfo.TimePercent?.param?.['show_shippingpercentage'] == 0 ? false : true
  },
  // abt开启快速运输
  showQuickShipByAbt(state, getters){
    return getters.isSiteMallList ? false : state.checkoutAbtInfo.TradeQuickship?.p == 'show_QuickShipping' ? true : false
  },
  // 购物袋入口位置
  shoppingBagPosition(state, getters){
    return getters.isSiteMallList ? true : state.checkoutAbtInfo.Shoppingbagposition?.param?.shoppingbag_position == 'old' ? false : true
  },
  showXtra(state) {
    return !!state.xtraProductInfo.product_code && !!state.xtraCouponList.length
  },
  // 沙特高货值信息必填abt
  completeHighValueInformation(state){
    return state.checkoutAbtInfo.CompleteHighvalueInformation?.param || {}
  },
  currencySupport(state){
    return state.checkout.results?.currencySupport || []
  },
  //是否全部为店铺商品
  isAllStore(state, getters){
    return getters.isShopType == 1
  },
  // 0：仅自营；1：仅店铺；2：既有店铺也有自营
  isShopType(state){
    const carts = state.checkout.results?.carts?.carts || []
    const hasStoreGoods = carts.filter(item => item.business_model == 1)
    const isAllStore = carts.length == hasStoreGoods.length
    return hasStoreGoods.length ? isAllStore ? 1 : 2 : 0
  },
  isShowbrTaxhint(state){
    return state.checkoutAbtInfo.brTaxhint?.param?.isSwitchOn == 1 ? true : false
  },
  isNewPrimeVersion(state) {
    return state.checkoutAbtInfo.OrderEntryprimeVersion?.param?.primeVersion == 'new' ? true : false
  },
  isShowPriceReminderByAbt(state) {
    return state.checkoutAbtInfo?.PriceReminder?.param?.PriceReminderswitch == 'show' ? true : false
  },
  touchRuleInfo(state) {
    // 当前不会存在配置多条的情况，默认取第一个处理
    const ruleList = state.price.touch_rule_list?.filter(item => item.rule_id > 0 && item.hint_type == 6 && !!item.msg) || []

    return ruleList[0] || {}
  },
  taxHint(state, getters) {
    const { tax = [] } = state.price || {}
    const taxList = tax.filter(item => +item.taxPrice?.amount > 0 && item.taxType == 7) || []
    // 当前不会存在配置多条的情况，默认取第一个处理
    const taxPrice = taxList[0]?.taxPrice?.amountWithSymbol || ''
    const taxHint = getters.touchRuleInfo.msg || ''

    return (!!taxList.length && taxHint) ? taxHint.replace('{br_tax_1}', taxPrice) : ''
  },
  primeRightCouponCodeList(state) {
    if(!state.rightConfigList.length) return []
    const freeRight = state.rightConfigList.filter(item => item.right_type_code == 'FREE_SHIPPING_TIMES') || []
    if(!freeRight.length) return []
    return freeRight[0]?.coupon_code_list || []
  },
  orderInterceptByAbt(state) {
    return state.checkoutAbtInfo.OrderInterceptionRules?.param?.InterceptionRulesStatus == 'on' ? true : false
  },
  // 是否是店配运输方式
  isShopTransit(state) {
    const defaultShipping = state.defaultShipping || []
    if (!defaultShipping.length || !defaultShipping[0]?.shipping_method?.transport_type) return false
    // 店配和宅配互斥,不同mall只能选择其中一种
    const matchShopType = defaultShipping.find(mall => TRANSPORT_TYPE_SHOP.includes(mall.shipping_method.transport_type))
    return !!matchShopType
  },
  forcedShippingByAbt(state) {
    return state.checkout?.abtInfo?.forcedShipping?.param == 'forcedShippingGuarantee'
  },
  switchQsFlagByAbt(state, getters) {
    let switch_qs_flag = 0
    const qsMakeup = state.checkout?.abtInfo?.quickshipFeeSwitch?.param?.qsMakeup || 0
  
    const singleMallCode = state.checkout?.results?.mallInfo?.mallInfoForSite?.[0]?.mall_code || ''
    const filterCarts = state.checkout?.results?.carts?.carts?.filter(item => item.mall_code == singleMallCode) || []
    const quickShipCarts = filterCarts?.filter(item => item.real_quick_ship == 1) || []
    const isAllQuickShip = !!quickShipCarts.length && (filterCarts.length == quickShipCarts.length)
  
    if (getters.showQuickShipByAbt && !isAllQuickShip) switch_qs_flag = qsMakeup
  
    return switch_qs_flag
  },
  userBehaviorAbt(state) {
    return state.checkout?.abtInfo?.UserBehaviorTips?.param || {}
  },
  lowestPriceDays(state) {
    return state.checkout?.abtInfo?.LowestPriceDays?.param || {}
  },
  showShippingInHeaderTitleByAbt(state) {
    return state.checkout?.abtInfo?.CheckoutHeadShippingEffect?.param.CheckoutHeadShippingEffect
  },
  chemicalsIds(state) {
    let { p65_flag = [] } = state.checkout?.mall_caculate_info || {}
    const p65FlagList = p65_flag?.filter(item => item.flag == 1)
    const skuArr = p65FlagList?.map(item => item.goods_sn)
    return skuArr || []
  },
  shipTimeDesList(state, getters) {
    const shipList = state.mallShippingMethods?.mall_list || []

    return shipList.map(mall => {
      return { 
        mall_code: mall?.mall_code || '', 
        shipping_methods: mall?.shipping_methods.map(method => {
          let shipping_time_desc = 'none'
          if (getters.timePercentAbt) {
            let isShowPercentDes = method.desc_type == 1 ? !!method?.delivery_day_percent_desc : !!method.shipping_day_percent_desc
            let isShowPercentIcon = !!method?.day_percents?.length
            if (isShowPercentDes && isShowPercentIcon) {
              shipping_time_desc = 'desc_and_rule'
            } else if (isShowPercentDes && !isShowPercentIcon) {
              shipping_time_desc = 'desc_only'
            }
          }

          return { transport_type: method.transport_type, shipping_time_desc }
        }) 
      }
    })
  },
  canEditShoppingbag(state) {
    if(state.locals?.IS_RW) return false
    return state.checkout?.abtInfo?.editShoppingbag?.param?.editstatus == '1'
  },
  flashSaleGroupList(state) {
    let result = {}
    const cartList = state.checkout?.results?.carts?.carts || []
    cartList.filter(item => item.promotionTypeIds?.some(i => [10, 11].includes(i))).forEach(item => {
      // 默认10普通闪购不会与11直播闪购同时存在于同一商品上
      let flashSalePromotion = item.product.product_promotion_info?.find(promotion => [10, 11].includes(promotion.type_id))
      if(flashSalePromotion){
        if(!result[flashSalePromotion.promotion_id]){
          result[flashSalePromotion.promotion_id] = []
        }
        result[flashSalePromotion.promotion_id].push(item)
      }
    })
    return result
  },
  underPriceGroupList(state) {
    let result = {}
    const cartList = state.checkout?.results?.carts?.carts || []
    cartList.filter(item => item.promotionTypeIds?.includes(30)).forEach(item => {
      let flashSalePromotion = item.product.product_promotion_info?.find(promotion => promotion.type_id == 30)
      if(flashSalePromotion){
        if(!result[flashSalePromotion.promotion_id]){
          result[flashSalePromotion.promotion_id] = []
        }
        result[flashSalePromotion.promotion_id].push(item)
      }
    })
    return result
  },
  discountPriceGroupList(state) {
    let result = {}
    const cartList = state.checkout?.results?.carts?.carts || []
    cartList.filter(item => item.promotionTypeIds?.includes(24)).forEach(item => {
      let flashSalePromotion = item.product.product_promotion_info?.find(promotion => promotion.type_id == 24)
      if(flashSalePromotion){
        if(!result[flashSalePromotion.promotion_id]){
          result[flashSalePromotion.promotion_id] = []
        }
        result[flashSalePromotion.promotion_id].push(item)
      }
    })
    return result
  },
  showOrderRulesByAbt(state) {
    if(state.locals?.IS_RW) return false
    return state.checkout?.abtInfo?.placeOrderOtherrules?.param?.placeOrderOtherrulesstatus == '1'
  },
  showOrderBubbleSort(state) {
    const sortList = state.checkout?.abtInfo?.placeOrderbubblesort?.param?.placeOrderbubblesortvalue || 'promotion,inventory,coupon'
    return sortList.toLocaleLowerCase().replace(/\s/g, '').split(',')
  },
  underPriceAbtValue (state) {
    return state.checkout?.abtInfo?.UnderPrice?.param?.UnderPriceShow == 'A' ?  true : false
  },
  // 最早到期的券
  earliestExpiryCoupon (state) {
    const now = Date.now()
    // 倒计时阈值可配置
    const threshold = +state.locals?.COUPON_COUNTDOWN_THRESHOLD || 48
    const maxOffset = threshold * 60 * 60 * 1000
    let coupon
    state.coupon.applyCouponsSuccess.forEach(item => {
      const originInfo = state.usableCouponList.find(coupon => coupon.coupon == item.couponCode)
      if (!originInfo) return
      // 排除会费券
      if ([58, 59].includes(+originInfo.real_type_id)) return
      let endTime = +originInfo.end_date
      if (endTime.toString().length < 13) endTime *= 1000
      const offset = endTime - now
      // 优先级：券到期时间越早，优先级越高
      const isEndFaster = !coupon || +originInfo.end_date < +coupon.originInfo.end_date
      if (offset > 0 && offset < maxOffset && isEndFaster) {
        coupon = { calculateInfo: item, originInfo }
      }
    })
    return coupon
  },
  autoRenewalPrimeShowAbt(state) {
    return state.checkout?.abtInfo?.sheinClubRenew?.param?.sheinClubRenewStatus == 1
  },
  // 当前产品包/自动用券产品包
  productAutoInfo(state){
    const { pay_member_discount_price = {}, prime_total_price = {} } = state?.checkout?.mall_caculate_info || {}
    const newPrimeProductList = state?.virtualCouponInfo?.newPrimeProductList
    const currentProduct = newPrimeProductList?.find(f => f.product_code === state.usedPrimeCode)?.primeProductList
    const currenctProductCycleDaysObj = newPrimeProductList.find(f => f?.primeProductList?.product_cycle_type === currentProduct?.product_cycle_type && f?.primeProductList?.product_activity_type != 1)
    const currentDaysPriceInfo = currenctProductCycleDaysObj?.primeProductList?.product_price_info || {}
    const discountPrice = +pay_member_discount_price?.amount <= 0 ? {
      ...prime_total_price,
      price: prime_total_price?.amount,
      price_with_symbol: prime_total_price?.amountWithSymbol
    } : {
      ...pay_member_discount_price,
      price: pay_member_discount_price?.amount,
      price_with_symbol: pay_member_discount_price?.amountWithSymbol
    }
    const result = {
      currentProduct,
      currentProductCycleDays: currenctProductCycleDaysObj?.primeProductList,
      limitedPayTypeList: state?.checkout?.results?.primeProductInfo?.limitedPayTypeList || [],
      discountPrice,
      normalShow: +currentDaysPriceInfo?.special_price >= +discountPrice?.price,
      // normalShow: +currentDaysPriceInfo?.special_price < +discountPrice?.price, //mock反
      scence: currenctProductCycleDaysObj?.scence
    }
    // console.log('result===', result)

    return result
  },
  shoppingBagCrossedPriceByAbt(state) {
    return state.checkout?.abtInfo?.Shoppingbagcrossedprice?.param?.add_shoppingbag_price_switch != 'off'
  },
  discountLogoByAbt(state) {
    return state.checkout?.abtInfo?.Discountlogo?.param?.add_discount_logo_switch != 'off'
  },
  isSelectTokenPay (state) {
    return state.status.cardPay === 'routepay-card' && state.isShowTokenPay && !!Object.keys(state.defaultSelectTokenPay).length
  },
  orderConfirmDiscountSwitchByAbt(state) {
    const SiteUID = state.locals?.SiteUID || gbCommonInfo?.SiteUID || ''
    const isBrSite = !!~SiteUID.indexOf('br')

    if(state.locals?.IS_RW || isBrSite) return false

    return state.checkout?.abtInfo?.orderConfirmDiscountSwitch?.param?.orderConfirmDiscountSwitchStatus == '1'
  },
  placeorderStatusmoveByAbt(state) {
    const SiteUID = state.locals?.SiteUID || gbCommonInfo?.SiteUID || ''
    const isBrSite = !!~SiteUID.indexOf('br')

    if(state.locals?.IS_RW || isBrSite) return false

    return state.checkout?.abtInfo?.placeorderStatusmove?.param?.placeorderStatusmoveStatus == '1'
  },
  saverNewStyleAbt(state) {
    return state.checkout?.abtInfo?.saverNewOrderStyle?.param?.saverNewOrderStyleStatus == 1
  },
  // 下单页面是否使用新版本按钮布局样式
  isShowNewFooterLayoutStyle(state) {
    return !state.locals?.IS_RW && state.checkout?.abtInfo?.placeOrderButtonUpgrade?.param?.placeOrderButtonUpgradeStatus == '1'
  },
  shoppingBagTypeAbt(state) {
    return state.checkout?.abtInfo?.ShoppingBagType?.param?.ShoppingBagType == 'Item_Detail'
  },
  showAddEntranceByAbt(state, getters){
    return getters.isSiteMallList ? false : state.checkout?.abtInfo?.CheckoutShippingAddEntracne?.param?.checkout_shipping_add_entracne_switch == 'on'
  },
  shippingTipEnhanceByAbt(state){
    return state.checkout?.abtInfo?.CheckoutShippingTipEnhance?.param?.checkout_shipping_tip_enhance_switch == 'on'
  },
  GBCssRight(state) {
    return state.locals?.GB_cssRight ?? gbCommonInfo?.GB_cssRight
  },
  // quickship
  quickShipCheckoutStatus(state){
    // qs => 只针对单mall场景 
    const singleMallCode = state.checkout?.results?.mallInfo?.mallInfoForSite?.[0]?.mall_code || ''

    const { TradeQuickshipWord, OrderQuickshipStyle, PlaceOrderQuickshipWord, quickshipFeeSwitch } = state?.checkout?.abtInfo || {}
    const qsMakeup = quickshipFeeSwitch?.param?.qsMakeup || 0
    const cartList = state.checkout?.results?.carts?.carts || []
    const list = state.defaultShipping?.filter(item => item.mall_code == singleMallCode) || []
    const qs_freight_status = list?.[0]?.shipping_method?.qs_freight_status
    const showQsFreight = qs_freight_status == 1 && qsMakeup != 0
    const quickShipWord = +(TradeQuickshipWord?.param?.TradeQuickshipWordStatus || 0)
    const result = {
      quickShipWord,
      // quickShipStyle: 0,
      quickShipStyle: +(OrderQuickshipStyle?.param?.OrderQuickshipStyleStatus || 0),
      PlaceOrderQuickshipWord: +(PlaceOrderQuickshipWord?.param?.PlaceOrderQuickshipWordStatus || 0),
      isAllCartsQuickShip: cartList.every((item) => item?.quick_ship && item?.real_quick_ship == 1),
      isCartsQuickShip: cartList.some((item) => item?.quick_ship && item?.over_quick_ship != 1),
      descQuickShipTime: state.descQuickShipTime,
      quickShipLabelText: quickShipWord ? template(quickShipWord, state.language.SHEIN_KEY_PWA_30733) : state.language.SHEIN_KEY_PWA_22276,
      showQsFreight,
      singleMallCode,
      defaultShipping: state.defaultShipping, // 组装过，只有mall_code 和 mall下对应的运输方式 => []
      mallShippingMethods: state.mallShippingMethods // mall + 运输方式 => defaultShipping 都来自于mallShippingMethods
    }
    // console.log('quickShipCheckoutStatus===', result)
    return result
  },
  clubScribePriceByAbt(state) {
    if(state.locals?.IS_RW) return false

    const SiteUID = state.locals?.SiteUID || gbCommonInfo?.SiteUID || ''
    // 法国、波兰、德国站点
    return state.checkout?.abtInfo?.ClubScribePrice?.param?.ClubScribePrice == 'noshow' && ['pwfr', 'mpl', 'pwde'].includes(SiteUID)
  },
  // 法国，波兰金额合规展示
  isSuggested(state) {
    return state.checkout.IS_SUGGESTED || false
  },
  // 德国金额合规展示
  isSuggestedInDe(state) {
    return state.checkout.IS_SUGGESTED === 'special_de'
  },
  shippingSlideByAbt(state) {
    return state.checkout?.abtInfo?.shippingVersion?.param?.isShippingVersion == 'new'
  },

  cardTokenList(state) {
    const routerPayCardData = (state.checkout?.results?.paymentMethods || [])?.find(item => item?.code === 'routepay-card')
    return routerPayCardData?.card_token_list || []
  },
  
  lastSelectePayment(state) {
    return state.lastSelectePayment
  },
  showNewCouponLanguage(state) {
    return state.checkout?.abtInfo?.Placeordercoupon?.param?.Placeordercouponvalue == 1
  },
  // 无地址（宅配）的时候，需要进入下单页面后才填写地址
  isNoAddressEnterCheckoutByAbt(state) {
    return !state.locals?.IS_RW && ['typeA', 'typeB', 'typeC'].includes(state.checkout?.abtInfo?.NoAddressInteract?.param?.NoAddressInteract)
  },
  // 无地址（宅配）需要进入下单页面后，填写地址后，这个值就是false
  isNotAddressScene(state, getter) {
    return getter.isNoAddressEnterCheckoutByAbt && !state.addresslist?.length
  },
  isSelectInstallmentTokenPay (state) {
    return state.status.cardPay === 'routepay-cardinstallment' && state.isShowTokenFrontInstallment
  },
  // 超省卡自动续费产品包
  xtraAutoController(state){
    const xtraAutoPayLimitInfo = (state.checkout?.results?.xtraProductInfo?.limitedPayTypeList || []).map(m => m.code)
    const sheinSaverRenewAbt = state.checkout?.abtInfo?.SheinSaverRenew?.param?.SheinSaverRenewStatus == 1
    // 多包，组装后的数据
    const xtraAllProductBagInfo = state.xtraAllProductBagInfo
    const xtraAllproduct = Object.values(xtraAllProductBagInfo).map(m => m.productInfo)
    const xtraProductAuto = xtraAllproduct.filter(f => f.product_activity_type == 1)
    const xtraProductNormal = xtraAllproduct.filter(f => f.product_activity_type != 1)
    const currentPaymenthods = state.status.cardPay
    const currentProduct = xtraAllproduct.find(s => s.product_code === state.usedXtraCode)
    const currentProductCycleDays = xtraAllproduct.find(f => f?.product_cycle_type === currentProduct?.product_cycle_type && f?.product_activity_type != 1)
  
    const result = {
      sheinSaverRenewAbt,
      autoPaymenodsGuideVisible: sheinSaverRenewAbt && !!xtraAutoPayLimitInfo?.length && !!xtraProductAuto?.length, // 前置展示条件，abt/是否有快捷支付/是否有自动续费包
      xtraCurrentAutoPayLimit: xtraAutoPayLimitInfo?.includes(currentPaymenthods), // 当前支付方式是否在快捷支付
      xtraAutoPayLimitInfo, // 自动包能使用的快捷支付方式
      xtraAllproduct, // 所有包
      xtraProductAuto, // 所有自动续费包
      xtraProductNormal, // 所有普通包
      currentProduct, // 当前包
      currentProductCycleDays, // 选中的自动续费超省卡产品同周期的普通包
      currentXtraIsAuto: currentProduct?.product_activity_type == 1, // 当前包是不是自动续费包
    }
    // console.log('xtraAutoController result', result)
    return result
  },
  // 是否开启evoluSHEIN标签
  switchOnEvoluSHEIN(state) {
    return state.checkout?.abtInfo?.evoluSHEINShow?.param?.evoluSHEIN_show === 'on'
  },
  // EVOLU_SHEIN_ID
  EVOLU_SHEIN_ID(state) {
    return state.checkout?.EVOLU_SHEIN_ID
  },
  sheinClubUIByAbt(state) {
    return state.checkout?.abtInfo?.Ordersheinclub?.param?.sheinclubui || 0
  },
  saverHeightByAbt(state) {
    return state.checkout?.abtInfo?.saverHeight?.param?.saverHeightStatus == 1
  },
  showArrivalPriceByAbt(state) {
    return state.checkout?.abtInfo?.priceOrderArrival?.param?.priceOrderArrivalStatus == 1
  },
  // 是否支持卡分期前置判断 目前仅支持卡分期
  isUsePrePayForCheckout(state, getter) {
    if (getter.isSelectInstallmentTokenPay) return false
    return state.status.cardPay === 'routepay-cardinstallment' && 
      state.checkout?.abtInfo?.cardinstallmentPaymentFront?.param?.cardinstallmentPaymentFrontStatus == 1
  },

  // 卡分期是否需要二次check
  isCheckCardinstallmentInfoStatus(state, getter) {
    if (getter.isSelectInstallmentTokenPay) return false
    return state.status.cardPay === 'routepay-cardinstallment' && 
      state.checkout?.abtInfo?.checkCardinstallmentInfo?.param?.checkCardinstallmentInfoStatus == 1 // 卡分期需要二次check
  }
}

export default getters
