import { daEventCenter } from 'public/src/services/eventCenter/index'

const checkoutReport = evt => {
  switch (evt.type) {
    case 'expose_info_popup':
      daEventCenter.triggerNotice({
        daId: '2-31-10-5',
        extraData: evt.data
      })
      break
    case 'click_confirm':
      daEventCenter.triggerNotice({
        daId: '2-31-10-6',
        extraData: evt.data
      })
      break
    case 'click_close':
      daEventCenter.triggerNotice({
        daId: '2-31-10-7',
        extraData: evt.data
      })
      break
    case 'click_input':
      daEventCenter.triggerNotice({
        daId: '2-31-10-8',
        extraData: evt.data
      })
      break
    default:
  }
}

export { checkoutReport }
