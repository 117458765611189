/*
 *  复制shein pay项目里的configs/payments.js
**/


//这里的url路径要唯一
const payInfos = {
  WDC_CARD: { code: 'worldpay-card', path: '/wdc/card/' },

  EBC_CARD: { code: 'ebanx-card', path: '/ebc/card/' },

  // 'apacpay-carddirect':		'/apc/card/',

  // 'apacpay-cardinstallment':	'/aps/card/',

  EMC_CARD: { code: 'ebanx-mxcardinstallment', path:	'/emc/card/' },

  EBCC_CARD: { code: 'ebanx-clcardinstallment', path: '/ebcc/card/' },

  EBBC_CARD: { code: 'ebanx-brcardinstallment', path: '/ebbc/card/' },

  IGC_CARD: { code: 'ingenico-card', path: '/igc/card/' },

  AYCD_CARD: { code: 'adyen-card3ds', path: '/aycd/card/' },

  AYC_CARD: { code: 'adyen-card', path:	'/ayc/card/' },

  WDC_JS_CARD: { code: 'worldpay-cardjs3ds', path: '/wdc2/card/' }, //pc和pwa已经没有这个页面了，但h5还要用3ds，这个不能去掉

  // 'Paytm-carddirect':			'/ptc/card/',

  // 'PayU-carddirect':			'/puc/card/',

  CTC_CARD: { code: 'checkout-card3ds', path: '/ctc/card/' },
  
  RPC_CARD: { code: 'routepay-card', path: '/rpc/card/' },

  DLC_CARD: { code: 'dlocal-card', path: '/dlc/card/' },

  KSF_CARD: { code: 'klarna-sofortjs', path: '/ksf/card/' },

  AYCD_JS_CARD: { code: 'adyen-cardjs', path: '/aycd/cardjs/' },

  AYCD_JSDS_CARD: { code: 'adyen-cardjs3ds', path: '/aycd/card2/' },

  // 'checkout-cardjs':			'/checkout/card/',

  // 'checkout-cardjs3ds':		'/checkout/card2/',

  DMC_CARD: { code: 'dlocal-mxcardinstallment', path: '/dmc/card/' },

  EBCO_CARD: { code: 'ebanx-cocardinstallment', path:	'/ebco/card/' },

  DLBR_CARD: { code: 'dlocal-brcardinstallment', path: '/cardinstallment/dlbr/' },

  DLCL_CARD: { code: 'dlocal-clcardinstallment', path: '/cardinstallment/dlcl/' },

  //PAI_CARD: { code: 'paidy-paylater', path: '/paidy/card/' },

  RPCI_CARD: { code: 'routepay-cardinstallment', path: '/rpci/card/' },
}

const payCodes = {}
Object.keys(payInfos).forEach(key => {
  payCodes[key] = payInfos[key].code
})

const payments = { payInfos, payCodes }

export default payments
