export function tokenPayStateFactory() {
  return {
    selectedTokenPayInfo: {},
    tokenCvv: '',
    tokenPayParams: {
      billno: '',
      payment_method: '',
      id: '',
      card_bin: '',
      last_four_no: '',
      tokenCvv: '',
      countryCode: ''
    }
  }
}
export default {
  namespaced: true,
  state: tokenPayStateFactory,
  getters: {
    // 对应支付方式选中的银行信息
    paymentBankInfo(state, getters, rootState) {
      console.log('paymentBankInfo---', state, getters, rootState)
      return state.banksConfig
    }
  },
  mutations: {
    updateSelectedTokenPayInfo(state, payload = {}) {
      state.selectedTokenPayInfo = Object.assign({}, state.selectedTokenPayInfo, payload)
      console.log('updateSelectedTokenPayInfo-==--', state.selectedTokenPayInfo)
    },
    updateTokenPayCvv(state, payload = '') {
      state.tokenCvv = payload || ''
    },
    setTokenPayParams(state, payload = {}) {
      state.tokenPayParams = Object.assign({}, {
        id: state.selectedTokenPayInfo?.id || '',
        card_bin: state.selectedTokenPayInfo?.card_bin || '',
        last_four_no: state.selectedTokenPayInfo?.last_four_no || '',
        tokenCvv: state.tokenCvv || ''
      }, payload)
    }
  },
  actions: {
    // handleBankSelect({ commit, state }, bankItem) {
    //   const { banksConfig = {}, bankDrawerConfig = {} } = state
    //   const configValue = banksConfig[bankDrawerConfig.paymentMethod] || {}
    //   console.log('bankDrawerConfig.paymentMethod----', state, bankDrawerConfig.paymentMethod)

    //   commit('updateBanksConfig', {
    //     [bankDrawerConfig.paymentMethod]: {
    //       ...configValue,
    //       bankCode: bankItem.code,
    //       bankCodeText: bankItem.name,
    //       bankLogo: bankItem.logo,
    //       error: false
    //     }
    //   })
    //   commit('updateBankDrawerConfig', {
    //     visible: false,
    //     paymentMethod: '',
    //     defaultCode: bankItem?.code
    //   })
    // },
    // handleStartSelectBank({ commit, state }, code) {
    //   commit('updateBankDrawerConfig', {
    //     defaultCode: state.banksConfig[code]?.bankCode || '',
    //     visible: true,
    //     bankList: state.banksConfig[code].list,
    //     paymentMethod: code
    //   })
    // },
    // resetBanksConfig({ commit, state }, resetData) {
    //   commit('updateBanksConfig', resetData)
    // }
  }
}
