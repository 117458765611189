const analysisCoupon = {
  click_checkoutcouponavailable () {
    // sa('send', { activity_name: 'click_checkoutcouponavailable' })
  },
  expose_checkoutcouponavailable () {
    sa('send', { activity_name: 'expose_checkoutcouponavailable' })
  },
  click_checkoutrecommendccoupon () {
    sa('send', { activity_name: 'click_checkoutrecommendccoupon' })
  },
  expose_checkoutrecommendccoupon () {
    sa('send', { activity_name: 'expose_checkoutrecommendccoupon' })
  },
  click_checkoutrecommendcouponcancel () {
    sa('send', { activity_name: 'click_checkoutrecommendcouponcancel' })
  },

  ShowMoreDeal () {
    sa('send', { activity_name: 'expose_moredeal' })
  },
  SelectMoreDeal () {},
  ClickViewCoupon () {
    sa('send', { activity_name: 'click_moredealviewcoupon' })
  },
  ClickClose_PopupMoreDealBox () {
    sa('send', { activity_name: 'click_popup_moredealboxclose' })
  },
  ClickPurchase_PopupMoreDealBox () {
    sa('send', { activity_name: 'click_popup_moredealboxbuy' })
  },
  ClickMoreDealRule () {
    sa('send', { activity_name: 'click_moredealrule' })
  },
  click_tickmorecopons () {
    sa('send', { activity_name: 'click_tickmorecopons' })
  },
  clickCancelCoupon () {
    sa('send', { activity_name: 'click_coupon_code_cancel', activity_param: '' })
  },
  DeleteCoupons () {},
  ApplyCoupons () {},
  SelectAvailablePayMethod () {
    sa('send', { activity_name: 'expose_popup_couponselectpaymethodbox' })
  },
  ClickCoupon () {},
  ApplyCouponNotFreeShipping () {
    sa('send', { 'activity_name': 'expose_popup_notfreeshipping' })
  }

}

export default analysisCoupon
