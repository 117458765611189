//该文件放一些公共枚举 

// 国家或者地区码，按照对用的code大小顺序书写
export const countryIdCodeEnum = {
  BRAZIL: 30, // 巴西 BR
  FRANCE: 74, // 法国 FR
  ISRAEL: 105, // 以色列 IL
  PALESTINE: 165, // 巴勒斯坦 PS
  RUSSIAN: 178, // 俄罗斯联邦 RU
  SAUDI_ARABIA: 186, // 沙特阿拉伯 SA
  SINGAPORE: 191, // 新加坡 SG
  SPAIN: 198, // 西班牙 ES
  TAIWAN: 209, // 中国台湾 TW
  UK: 225, // 英国 UK
  US: 226, // 美国 US
}
