import schttp from 'public/src/services/schttp'
import { getCookie } from '@shein/common-function'
import UserInfoManager from 'public/src/services/UserInfoManager/UserInfoManager.js'
import { checkoutReport as reportBankDialog }  from 'public/src/pages/checkout/tools/report_bank_dialog.js'

export function bankSelectStateFactory() {
  return {
    // 支付方式银行配置
    banksConfig: {},
    bankDrawerConfig: {
      visible: false,
      bankList: [],
      paymentMethod: '',
      defaultCode: ''
    },
    // 南非capitec银行信息
    capitecDialog: {
      visible: false,
      isCollect: false,
      isDlocalPse: false,
      phone: '',
      email: '',
      countryId: '',
      paymentMethod: '',
      bankInfo: {},
      phoneCountryNum: '',
      phoneRule: {
        // 'reg': '^[0-9]{5,30}$',
        // 'copywrite': 'Phone Number should contain 5-30 digits no other characters.'
      },
      emailRule: {
        reg: /^([\w-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,6}|[0-9]{1,3})(\]?)$/,
        copywrite: ''
      }
    }
  }
}
export default {
  namespaced: true,
  state: bankSelectStateFactory,
  getters: {
    // 对应支付方式选中的银行信息
    paymentBankInfo(state, getters, rootState) {
      console.log('paymentBankInfo---', state, getters, rootState)
      return state.banksConfig
    }
  },
  mutations: {
    updateBankDrawerConfig(state, payload) {
      state.bankDrawerConfig = Object.assign(
        {},
        state.bankDrawerConfig,
        payload
      )
    },
    updateBanksConfig(state, payload = {}) {
      state.banksConfig = Object.assign({}, state.banksConfig, payload)
    },
    updateCapitecDialog(state, payload = {}) {
      state.capitecDialog = Object.assign({}, state.capitecDialog, payload)
    }
  },
  actions: {
    async fetchUserPaymentRelatedInfo({ state, commit }, code) {
      let res = await schttp({
        url: '/api/checkout/userPaymentRelatedInfo',
        params: {
          paymentCode: code
        }
      })
      let phone = state.capitecDialog.phone || ''
      let email = await UserInfoManager.get({ key: 'pwa_user_email' }) || ''
      if (res.code === '0' && res.info) {
        if (res.info.email) email = res.info.email
        if (res.info.phone) phone = res.info.phone 
      } 
      commit('updateCapitecDialog', {
        visible: true,
        phone: phone,
        email: email
      })
    },
    // 获取email信息
    async fetchUserEmailInfo({ state, commit }, code) {
      let res = await schttp({
        url: '/api/checkout/userPaymentRelatedInfo',
        params: {
          paymentCode: code
        }
      })
      let email = await UserInfoManager.get({ key: 'pwa_user_email' }) || ''
      if (res.code === '0' && res.info) {
        if (res.info.email) email = res.info.email
      } 
      commit('updateCapitecDialog', {
        visible: true,
        email: email
      })
    },
    async fetchTelCheckRuleApi({ state, commit }) {
      let countryId = state.capitecDialog.countryId || '196'
      let res = await schttp({
        url: '/api/checkout/telCheckRule',
        params: {
          country_id: countryId
        }
      })
      if (res.code === '0' && res.info?.result?.regex_list?.length) {
        let phoneRule = res.info.result.regex_list[0]
        commit('updateCapitecDialog', {
          phoneRule: phoneRule
        })
      }
    },
    async fetchTelCountryList({ state, commit }) {
      let countryId = state.capitecDialog.countryId || '196'
      let res = await schttp({
        url: '/api/checkout/telCountryList',
        params: {
          country_id: countryId
        }
      })
      if (res.code === '0' && res.info?.value && res.info?.countrynum ) {
        let phoneCountryNum = `${res.info.value}+${res.info.countrynum}`
        commit('updateCapitecDialog', {
          phoneCountryNum: phoneCountryNum
        })
      }
    },
    handleCapitecDialogEvent({ state, commit, dispatch }, payload) {
      !payload.isDlocalPse && reportBankDialog(payload)
      if(payload.type === 'submit'){
        commit('updateCapitecDialog', {
          visible: false,
          phone: payload.phone,
          email: payload.email
        })
        dispatch('saveSelectBank', state.capitecDialog.bankInfo)
      }
    },
    async handleBankSelect({ commit, state, dispatch }, bankItem) {
      const { bankDrawerConfig = {} } = state
      if (bankDrawerConfig.paymentMethod === 'dlocal-pse') {
        commit('updateCapitecDialog', { paymentMethod: bankDrawerConfig.paymentMethod, bankInfo: bankItem, 
          isDlocalPse: bankDrawerConfig.paymentMethod === 'dlocal-pse' })
        dispatch('fetchUserEmailInfo', bankDrawerConfig.paymentMethod)
      } else if (
        bankDrawerConfig.paymentMethod === 'dlocal-eft' &&
        bankItem.name === 'Capitec bank'
      ) {
        commit('updateCapitecDialog', { paymentMethod: bankDrawerConfig.paymentMethod, bankInfo: bankItem, isCollect: true })
        dispatch('fetchUserPaymentRelatedInfo', bankDrawerConfig.paymentMethod)
        dispatch('fetchTelCheckRuleApi')
        dispatch('fetchTelCountryList')
      } else {
        commit('updateCapitecDialog', { bankInfo: {}, isCollect: false })
        dispatch('saveSelectBank', bankItem)
      }
    },
    saveSelectBank({ commit, state }, bankItem) {
      const { banksConfig = {}, bankDrawerConfig = {} } = state
      const configValue = banksConfig[bankDrawerConfig.paymentMethod] || {}
      console.log(
        'bankDrawerConfig.paymentMethod----',
        state,
        bankDrawerConfig.paymentMethod
      )

      commit('updateBanksConfig', {
        [bankDrawerConfig.paymentMethod]: {
          ...configValue,
          bankCode: bankItem.code,
          bankCodeText: bankItem.name,
          bankLogo: bankItem.logo,
          error: false
        }
      })
      commit('updateBankDrawerConfig', {
        visible: false,
        paymentMethod: '',
        defaultCode: bankItem?.code
      })
      window?.appEventCenter?.$emit('capitecBankSaved')
    },
    handleStartSelectBank({ commit, state }, code) {
      commit('updateBankDrawerConfig', {
        defaultCode: state.banksConfig[code]?.bankCode || '',
        visible: true,
        bankList: state.banksConfig[code].list,
        paymentMethod: code
      })
    },
    resetBanksConfig({ commit }, resetData) {
      commit('updateBanksConfig', resetData)
    }
  }
}
