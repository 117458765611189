import { template } from '@shein/common-function'
import { labelTypeMap } from 'public/src/pages/components/product/item_v3/js/constant.js'
import schttp from 'public/src/services/schttp'
/**
 * 获取支付限制
 * @param {Array} currencySupport 支付方式币种配置
 * @param {String} currency 当前币种
 * @param {String} paymentCode 支付方式编码，如 oxxo, paypal ..
 * @param {String} countryCode 运输国家简码，如 US, MX ..
 * ---------------
 * @returns {Boolean} isChangeCurrency 是否需要切换币种
 * @returns {Object} currentPayment 当前支付方式的币种配置
 */
export function handleSwitchCurrency ({ currencySupport = [], currency, paymentCode, countryCode }) {
  // 强制切换币种
  let isChangeCurrency = false
  let currentPayment = null // 当前可用的支付配置
  let currentPaymentWithoutCountryCode = null // 当不限制国家时的支付方式配置

  if (paymentCode) {
    // 获取当前支付方式配置
    const currentPaymentSupports = currencySupport.filter(item => item.payMethod == paymentCode)
    if (currentPaymentSupports.length == 0) {
      return {
        isChangeCurrency,
        currentPayment
      }
    }

    for (const i in currentPaymentSupports) {
      if (currentPaymentSupports[i].countryCode == countryCode && countryCode != '') {
        // 有限制国家的配置，直接取，跳出循环
        currentPayment = currentPaymentSupports[i]
        break
      }
      if (currentPaymentSupports[i].countryCode === null) {
        // 非国家限制，兜底
        currentPaymentWithoutCountryCode = currentPaymentSupports[i]
      }
    }

    // 优先取有国家限制的支付方式，没有就取无国家限制的
    currentPayment = currentPayment || currentPaymentWithoutCountryCode

    if (currentPayment) {
      const { currencySupportList = [], defaultCurrency } = currentPayment
      const isSupport = currencySupportList.some(item => item == currency)

      // 若不支持此币种
      if (!isSupport && defaultCurrency) {
        isChangeCurrency = true
      }
    }
  }

  return {
    isChangeCurrency,
    currentPayment
  }
}

/**
 * 获取营销裂变提示语
 * @param {Object} extraPromotion 营销裂变信息对象
 */
export function transformExtraPromotionTip ({ language = {}, extraPromotion = {} } = {}) {
  const rule = extraPromotion?.marketFission?.rule || {}
  const { reason = 0, differencePrice } = rule
  if (reason == 0) return
  const priceTag = `<em class="tip-red">${differencePrice.amountWithSymbol}</em>`

  const reasonLanuageMap = {
    '1': language.SHEIN_KEY_PWA_18108,
    '2': language.SHEIN_KEY_PWA_18109,
    '3': language.SHEIN_KEY_PWA_18110,
    '4': language.SHEIN_KEY_PWA_18111,
  }
  return template(priceTag, reasonLanuageMap[reason])
}

/**
 * 判断购物车中是否存在某促销活动
 * @param {Array} carts 购物车商品
 * @param {Array} activityTypeIds 促销活动typeId
 * @return activity_method 0存在 1不存在
 */
export const judgeActivityIsExist = (carts = [], activityTypeIds = []) => {
  let activity_method = 1
  carts.forEach(item => {
    item?.product?.product_promotion_info?.forEach(info => {
      if (activityTypeIds.includes(+info.type_id)) {
        activity_method = 0
      }
    })
  })
  return activity_method
}

const getXtraCouponCode = ({ xtraProductBagInfo = [], xtraDiscountInfo = {} }) => {
  return xtraProductBagInfo.map(val => {
    let coupon_code = ''
    const discountInfo = xtraDiscountInfo?.allPaymentRightInfo?.[val.product_code] || {}
    const { total_discount } = discountInfo
    
    if(+total_discount?.amount > 0) {
      coupon_code = discountInfo.discount_values?.[0]?.coupon_code || ''
    } else {
      const rightConfig = val.right_config_list?.filter(item => item.privilege_cycle_num == 1) || []
      const privilege_info_list = rightConfig[0]?.privilege_info_list || []

      coupon_code = privilege_info_list[0]?.coupon_code_list?.[0]?.coupon_code || ''
    }

    return ({ [val.product_code]: coupon_code.toUpperCase()})
  })
}

const getXtraCouponType = ({ productInfo, couponCode }) => {
  const privilegeInfoList = productInfo.right_config_list?.map(item => item.privilege_info_list).flat(Infinity) || []
  const couponCodeList = privilegeInfoList.map(item => item.coupon_code_list).flat(Infinity) || []
  const couponTypeIndex = couponCodeList.findIndex(coupon => coupon.coupon_code?.toUpperCase() == couponCode.toUpperCase())

  return couponCodeList[couponTypeIndex]?.coupon_style || 0
}

export const getXtraDisplayData = async ({ xtraProductBagInfo = [], xtraDiscountInfo = {} }) => {
  const couponCodeData = getXtraCouponCode({ xtraProductBagInfo, xtraDiscountInfo })
  const coupon_code = couponCodeData.map(item => Object.values(item)?.[0] || '')

  const data = await getCouponListForXtra(coupon_code) || []

  let result = {}
  xtraProductBagInfo?.forEach(val => {
    const productCode = val?.product_code
    const couponCode = couponCodeData.find(i => !!i[productCode])?.[productCode] || ''

    const count = val.right_config_list?.map(item => +item.coupon_num).reduce((prev, curr) => {
      return prev + curr
    }, 0) || 0

    const couponType = getXtraCouponType({ productInfo: val, couponCode })
    result[productCode] = {
      type: couponType, count, couponCode,
      info: data?.find(_ => _.couponCode.toUpperCase() == couponCode) || {}
    }
  })
  return { data: result }
}
const getXtraMutipleBagCouponList = (xtraProductInfo) => {
  return new Promise((resolve) => {
    schttp({
      method: 'POST',
      url: '/api/checkout/xtraCouponList/update',
      data: {
        xtraProductInfo
      }
    }).then(res => {
      if(res.code == 0 && res.info) {
        resolve(res.info || {})
      } else {
        resolve({})
      }
    }).catch(() => {
      resolve({})
    })
  })
}
export const updateXtraCouponList = async (xtraProductBagInfo) => {
  let xtraCouponListInfo = {}
  
  const result = await Promise.all(xtraProductBagInfo.map(async item => {
    return getXtraMutipleBagCouponList(item)
  }))

  xtraProductBagInfo.forEach((_, index) => { xtraCouponListInfo[_.product_code] = result[index] })

  return xtraCouponListInfo
}

export const getCouponListForXtra = (coupon_codes) => {
  return new Promise((resolve) => {
    schttp({
      method: 'POST',
      url: '/api/coupon/getCouponListForXtra/query',
      data: {
        coupon_codes: coupon_codes,
        ignore_check: 1,
      }
    }).then(res => {
      if(res.code == 0 && res.info) {
        resolve(res.info.list || [])
      } else {
        resolve([])
      }
    }).catch(() => {
      resolve([])
    })
  })
}

export function publishCartCheckout(){
  let cacheCartInfoStr = sessionStorage.getItem('tpmCacheCart')
  if(cacheCartInfoStr) {
    let cacheCartInfo = null
    try{
      cacheCartInfo = JSON.parse(cacheCartInfoStr)
    } catch(e) {
      console.log(e)
    }
    if(cacheCartInfo) {
      window.TPM?.publish('checkout', {
        carts: cacheCartInfo.carts, 
        currency: cacheCartInfo.currency,
        prices: { totalPrice: cacheCartInfo.totalPrice }
      })
    }
    sessionStorage.removeItem('tpmCacheCart')
  }
}

export function debuggerLog(...args) {
  if (gbCommonInfo?.NODE_SERVER_ENV === 'production') return
  try {
    console.info('%cCheckout:', 'background:#35495e ; padding: 3px 3px; border-radius: 3px;  color: #fff', ...args, '\n')
  } catch (e) {
    console.log(...args)
  }
}

/**
 * 获取用于埋点的 goods_list 参数
 * @param {Array} products 需要上报的已曝光推荐商品列表
 * @param {Number} location 1: 支付方式上方; 2: 支付方式下方; 3: 虚拟资产下方;
 * @param {Number} index 商品的位置，从上到下，从左到右
 * @return {String}
 */
export const getGoodsListForAnalysis = (products, location, newCard) => {
  let exposedList = products

  // goods_id`skc`spu_id`坑位`页码`运营位置`流量标识rec_mark`置顶标识extra_mark`价格标识`其它标识`销售或促销属性`mall_mallcode`算法额外标识
  const reportKeys = [
    'goodsId',
    'skc',
    'spuId',
    'index',
    'page',
    'location',
    'recMark',
    'extraMark',
    'priceMark',
    'otherMark',
    'promotion',
    'salesLabels',
    'mallCode',
    'algorithmExtraMark'
  ]
  let results = exposedList.map((item, index) => {
    const { 
      quickship, 
      promotionInfo, 
      brand,
      goods_id, 
      goods_sn, 
      productRelationID,
      ext,
      dynamic_ext,
      salePrice,
      retailPrice,
      mall_code,
      sku_attr_list,
      mobileVerticalView,
      swiperIndex
    } = item
    let otherMark = [], salePromotion = []
    // 其他标识 - quickship
    // otherMark.push(`quickship_${quickship}`)
    if (quickship) otherMark.push(`show_service_label_QuickShip`)


    // 销售属性
    const valueNames = sku_attr_list?.filter(i => i.is_main === 0)?.map(i => i.attr_value_multi_name)?.join('/')
    salePromotion.push(valueNames ? `attribute_show_${valueNames}` : 'attribute_null')
    // 促销属性
    if (promotionInfo.length) {
      let is_brand = brand?.name ? 1 : 0
      let brandCode = brand?.brand_code
      promotionInfo?.some(item => +item?.typeId === 32) && otherMark.push('estimated_price_3')
      promotionInfo.forEach(ele => {
        salePromotion.push(['sale', ele.typeId, ele.id, is_brand, brandCode].join('_') )
      })
    }
    const values = {
      goodsId: goods_id,
      skc: goods_sn,
      spuId: productRelationID,
      index: swiperIndex !== undefined ? Number(swiperIndex) + 1 : index + 1,
      page: 1,
      location,
      recMark: ext?.rec_mark ?? '',
      extraMark: dynamic_ext?.extra ?? '',
      priceMark: `pri_${salePrice?.usdAmount}|pri_${retailPrice?.usdAmount}`,
      otherMark: otherMark.length ? otherMark.join('|') : '',
      promotion: salePromotion.length ? salePromotion.join('|') : '',
      mallCode: `mall_${mall_code}`,
      algorithmExtraMark: dynamic_ext?.other_d_ext_mark ?? '',
      salesLabels: mobileVerticalView?.salesLabels?.labelLang && newCard == 'A' ? `show_sales_label_${labelTypeMap.get(mobileVerticalView?.salesLabels.textType)}_${mobileVerticalView?.salesLabels.labelId}_${mobileVerticalView?.salesLabels.labelLang?.replace(/\s+/g, '_')}` : ''
    }

    return reportKeys.map(key => values[key]).join('`')
  })

  return results.join(',')
}
export function deepCopy(obj) {
  if (typeof obj !== 'object' || obj === null) {
    return obj
  }
  
  let copy = Array.isArray(obj) ? [] : {}
  for (let key in obj) {
    if (obj.hasOwnProperty(key)) {
      copy[key] = deepCopy(obj[key])
    }
  }
  return copy
}

// abt/没有快捷 => 自动续费包过滤掉
export function autoPrimeProducfilter({ sheinClubRenew, primeProductInfo, paymentMethods }){
  let { primeProductList, limitedPayTypeList } = primeProductInfo
  const limitedPayTypeListCode = (limitedPayTypeList || []).map(m => m.code)
  const isLimitedPayUsed = paymentMethods.some(s => limitedPayTypeListCode.includes(s.code) && s.enabled == 1)
  
  if(sheinClubRenew != 1 || !limitedPayTypeList || limitedPayTypeList?.length <= 0 || !isLimitedPayUsed){
    primeProductList = primeProductList.filter(f => f?.product_activity_type != 1)
  }
  return primeProductList
}

export function cartBusinessModelList(currentThis){
  const carts = currentThis?.checkout?.results?.carts?.carts || []
  return carts?.length ? [...new Set(carts.map(m => m?.business_model))] : []
}

/**
 * 根据不同的状态，输出场景
 * 是否有特价showDiscount、是否有分人群活动价isPreciseType、是否有可用的会费劵couponRightsCode、是否已勾选超省卡usedXtraCode、是否已经在下单页实际使用了会费券applyCouponsVir
 *  */
export const scenceBuildResult = ({ showDiscount, isPreciseType, couponRightsCode, usedXtraCode, applyCouponsVir }
= { showDiscount: false, isPreciseType: false, couponRightsCode: false, usedXtraCode: false, applyCouponsVir: false }) => {
  let result = 0
  if(!showDiscount && !isPreciseType && !couponRightsCode && !usedXtraCode)result = 1
  if(!showDiscount && !isPreciseType && !couponRightsCode && usedXtraCode)result = 2
  if(showDiscount && !isPreciseType && !couponRightsCode && !usedXtraCode)result = 3
  if(showDiscount && !isPreciseType && !couponRightsCode && usedXtraCode)result = 4
  if(isPreciseType && !couponRightsCode && usedXtraCode)result = 6
  if(!showDiscount && !isPreciseType && couponRightsCode && !usedXtraCode && !applyCouponsVir)result = 7
  if(!showDiscount && !isPreciseType && couponRightsCode && usedXtraCode && applyCouponsVir)result = 9
  if(!showDiscount && !isPreciseType && couponRightsCode && usedXtraCode && !applyCouponsVir)result = 8
  if(showDiscount && !isPreciseType && couponRightsCode && !usedXtraCode && !applyCouponsVir)result = 10
  if(showDiscount && !isPreciseType && couponRightsCode && usedXtraCode && !applyCouponsVir)result = 11
  if(showDiscount && !isPreciseType && couponRightsCode && usedXtraCode && applyCouponsVir)result = 12
  if(isPreciseType && couponRightsCode && !usedXtraCode && !applyCouponsVir)result = 13
  if(isPreciseType && couponRightsCode && usedXtraCode && !applyCouponsVir)result = 14
  if(isPreciseType && couponRightsCode && usedXtraCode && applyCouponsVir)result = 15
  if(isPreciseType && !couponRightsCode && !usedXtraCode) result = 5
  
  return result
}

// 超省卡根据包code，输出场景下对应的状态
export const scenceBuildStatus = (xtraAllproduct = [], showTipsArrowAbt = false) => {
  const sceneResultAll = deepCopy(xtraAllproduct)
  let resultObjct = {
    'default': {
      isVisible: false,
      iconDoubt: false,
      isTips: false,
      isLeg: false
    }
  }
  if(!sceneResultAll?.length) return resultObjct
  sceneResultAll.forEach(m => {
    const result = {
      isVisible: [10, 12, 13, 15].includes(m._xtraSceneDiscount) ? true : false, // 点击是否出优惠弹窗
      iconDoubt: [13, 15].includes(m._xtraSceneDiscount) && showTipsArrowAbt ? true : false, // 优惠弹窗里面是否展示法务弹窗的问号
      isTips: m.product_activity_type == 1 ? [7, 8, 11, 14].includes(m._xtraSceneDiscount) : [8, 11, 14].includes(m._xtraSceneDiscount), // 跳转到券中心（如果是自动续费产品包的时候，需要增加场景7）
      isLeg: [5, 6].includes(m._xtraSceneDiscount) && showTipsArrowAbt ? true : false, //点击是否出现法务弹窗
      productInfo: m, // 包体信息
      _xtraSceneDiscount: m._xtraSceneDiscount, // 当前是在哪个场景
    }
    resultObjct[m.product_code] = result
  })
  return resultObjct
}

